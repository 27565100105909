h1 {
  @include h1-styles();
}

h2 {
  @include h2-styles();
}

h3 {
  @include h3-styles();
}

h4 {
  @include h4-styles();
}

h5 {
  @include h5-styles();
}

h6 {
  @include h6-styles();
}

body,
p,
.body1 {
  @include body-styles();
}

.body2 {
  @include body-small-styles();
}

label {
  @include label-styles();
}

a {
  @include a-styles();
}

.qd-form-control-styles {
  @include form-control-styles();
}