
@mixin h1-styles() {
  margin: 0;
  font-family: $font-family;
  font-style: normal;
  font-weight: 800;
  font-size: 2.75rem;
  line-height: 3.75rem;
  text-transform: uppercase;
  color: $color-text !important;
  background-color: unset !important;
}

@mixin h2-styles() {
  margin: 0;
  font-family: $font-family;
  font-style: normal;
  font-weight: 800;
  font-size: 2.5rem;
  line-height: 3.375rem;
  text-transform: uppercase;
  color: $color-text !important;
  background-color: unset !important;
}

@mixin h3-styles() {
  margin: 0;
  font-family: $font-family;
  font-style: normal;
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 3.125rem;
  color: $color-primary !important;
  background-color: unset !important;
}

@mixin h4-styles() {
  margin: 0;
  font-family: $font-family;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.75rem;
  color: $color-text !important;
  background-color: unset !important;
}

@mixin h5-styles() {
  margin: 0;
  font-family: $font-family;
  font-style: normal;
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 2.5rem;
  color: $color-text !important;
  background-color: unset !important;
}

@mixin h6-styles() {
  margin: 0;
  font-family: $font-family;
  font-style: normal;
  font-weight: bold;
  font-size: 1.75rem;
  line-height: 2.375rem;
  text-transform: uppercase;
  color: $color-text !important;
  background-color: unset !important;
}

@mixin body-styles {
  margin: 0;
  font-family: $font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 2.75rem;
  color: $color-text !important;
  background-color: unset !important;
}

@mixin body-small-styles {
  margin: 0;
  font-family: $font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 2rem;
  color: $color-text !important;
  background-color: unset !important;
}

@mixin label-styles {
  @include body-small-styles();
  display: block;
  color: $color-text-muted !important;
  background-color: unset !important;
}

@mixin form-control-styles {
  margin: 0;
  font-family: $font-family;
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.75rem;
  color: $color-text;
}

@mixin a-styles {
  margin: 0;
  font-family: $font-family;
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -ms-touch-action: manipulation;
  touch-action: manipulation;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline-color: $color-primary;
  }
}

@mixin reset-button-styles {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;

  &:focus {
    outline-color: $color-primary;
  }
}

@mixin btn-style {
  @include reset-button-styles();
  @include form-control-styles();
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: component-size(0.75rem) component-size(2rem);
  min-width: 18rem;
  background-color: $color-primary;
  border: 2px solid $color-primary;
  border-radius: $roundness;
  text-align: center;
  text-transform: uppercase;
  color: $color-1;
  text-decoration: none;

  &:hover,
  &:focus {
    box-shadow: $shadow-glow-primary;
    -webkit-tap-highlight-color: transparent;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  &:focus {
    outline: $color-primary dotted 1px;
    outline-offset: -1px;
  }

  &:active {
    background: $color-primary;
    box-shadow: unset;
  }

  &:disabled {
    cursor: not-allowed;
    background: $color-3;
    color: $color-text-disabled;
    border-color: $color-3;

    &:hover {
      box-shadow: unset;
    }
  }
}

@mixin btn-secondary-style {
  @include btn-style();
  background-color: $color-1;
  color: $color-primary;

  &:active {
    background: $color-select;
    box-shadow: unset;
  }

  &:disabled {
    color: $color-text-disabled;
    border-color: $color-text-disabled;
  }
}

@mixin custom-transition($param, $seconds, $type) {
  transition: #{$param} #{$seconds}s $type;
  -webkit-transition: #{$param} #{$seconds}s $type;
  -moz-transition: #{$param} #{$seconds}s $type;
  -o-transition: #{$param} #{$seconds}s $type;
}

@mixin horizontal-center-transform() {
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
}

@mixin vertical-center-transform() {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

@mixin before-with-border() {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
  border-radius: $roundness;
  pointer-events: none;
}

@mixin text-ellipsis {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin unset-text-ellipsis {
  white-space: unset;
  text-overflow: unset;
  overflow: unset;
}

@mixin gray-container-styles{
  background: $color-2;
  padding: component-size(2rem);
  border-radius: $roundness;
}