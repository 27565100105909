// CSS resets & global layout

html {
  font-size: $html-font-size;
}

body {
  font-family: $font-family;
  margin: 0;
  padding: 0;
  min-width: 320px;
  background-color: $color-1 !important;
  background-image: $background-image;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

* {
  box-sizing: border-box;
}

hr {
  margin: 0;
  border: unset;
  border-bottom: 1px solid $color-3;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: $color-2;
  border-radius: $roundness;
}

::-webkit-scrollbar-thumb {
  background: $color-primary;
  border-radius: $roundness;
}

::-webkit-scrollbar-thumb:hover {
  background: $color-primary;
}