// Sass variables only

// Units:
$font-family: var(--font-family);
$html-font-size: var(--html-font-size);
$component-size: var(--size-components);
$spacing: var(--size-spacing);
$roundness: var(--roundness);
$layout-roundness: var(--layout-roundness);

// Colors
$color-primary: var(--color-primary);
$color-select: var(--color-select);
$color-text: var(--color-text);
$color-text-muted: var(--color-text-muted);
$color-text-disabled: var(--color-text-disabled);
$color-1: var(--color-1);
$color-2: var(--color-2);
$color-3: var(--color-3);
$color-4: var(--color-4);
$color-success: var(--color-success);
$color-warning: var(--color-warning);
$color-danger: var(--color-danger);
$color-select-success: var(--color-select-success);
$color-select-warning: var(--color-select-warning);
$color-select-danger: var(--color-select-danger);

// Header colors:
$header-color: var(--header-color);
$header-text-color: var(--header-text-color);

// Shadows:
$shadow-sm: var(--shadow-sm);
$shadow-md: var(--shadow-md);
$shadow-lg: var(--shadow-lg);
$shadow-glow-primary: var(--shadow-glow-primary);
$shadow-glow-success: var(--shadow-glow-success);
$shadow-glow-error: var(--shadow-glow-error);

// Images:
$background-image: var(--background-image);
$header-logo: var(--header-logo);

// Header logo styles
$header-logo-height: var(--header-logo-height);
$header-logo-margin: var(--header-logo-margin);

// Screens:
$desktop-min-width: 1024px;
$tablet-max-width: 1023px;
$tablet-min-width: 640px;
$mobile-max-width: 639px;
$mobile-min-width: 320px;

// NavSteps widths:
$large-nav-steps-width: 280px;
$small-nav-steps-width: 240px;
$dekstop-banner-container-width: 245px;

// LoanRequestBox Texts
$loan-req-commercial-title: var(--loan-req-commercial-title);
$loan-req-commercial-description: var(--loan-req-commercial-description);
$loan-req-commercial-cta: var(--loan-req-commercial-cta);
$loan-req-consumer-title: var(--loan-req-consumer-title);
$loan-req-consumer-description: var(--loan-req-consumer-description);
$loan-req-consumer-cta: var(--loan-req-consumer-cta);