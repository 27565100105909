.preview-table {
  width: 100%;

  tr {
    td {
      width: 50%;
      padding: 0;
    }

    td:first-child {
      padding-right: 0.25rem;
      text-align: right;
    }

    td:not(:first-child) {
      padding-left: 0.25rem;
    }
  }
}

.history-table-wrapper {
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;

    tr {
      th,
      td {
        padding: 1rem;
        text-align: left;
        border-bottom: 1px solid $color-4;
      }

      th:first-child,
      td:first-child {
        padding-left: 0;
      }
    }
  }
}