/* nunito-regular - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Nunito/nunito-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/Nunito/nunito-v16-latin-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Nunito/nunito-v16-latin-regular.woff2') format('woff2'), url('../fonts/Nunito/nunito-v16-latin-regular.woff') format('woff'), url('../fonts/Nunito/nunito-v16-latin-regular.ttf') format('truetype'), url('../fonts/Nunito/nunito-v16-latin-regular.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-600 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/Nunito/nunito-v16-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/Nunito/nunito-v16-latin-600.eot?#iefix') format('embedded-opentype'), url('../fonts/Nunito/nunito-v16-latin-600.woff2') format('woff2'), url('../fonts/Nunito/nunito-v16-latin-600.woff') format('woff'), url('../fonts/Nunito/nunito-v16-latin-600.ttf') format('truetype'), url('../fonts/Nunito/nunito-v16-latin-600.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-700 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Nunito/nunito-v16-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/Nunito/nunito-v16-latin-700.eot?#iefix') format('embedded-opentype'), url('../fonts/Nunito/nunito-v16-latin-700.woff2') format('woff2'), url('../fonts/Nunito/nunito-v16-latin-700.woff') format('woff'), url('../fonts/Nunito/nunito-v16-latin-700.ttf') format('truetype'), url('../fonts/Nunito/nunito-v16-latin-700.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-800 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/Nunito/nunito-v16-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/Nunito/nunito-v16-latin-800.eot?#iefix') format('embedded-opentype'), url('../fonts/Nunito/nunito-v16-latin-800.woff2') format('woff2'), url('../fonts/Nunito/nunito-v16-latin-800.woff') format('woff'), url('../fonts/Nunito/nunito-v16-latin-800.ttf') format('truetype'), url('../fonts/Nunito/nunito-v16-latin-800.svg#Nunito') format('svg'); /* Legacy iOS */
}


/* ibm-plex-serif-regular - latin */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/IBMPlexSerif/ibm-plex-serif-v9-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/IBMPlexSerif/ibm-plex-serif-v9-latin-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/IBMPlexSerif/ibm-plex-serif-v9-latin-regular.woff2') format('woff2'), url('../fonts/IBMPlexSerif/ibm-plex-serif-v9-latin-regular.woff') format('woff'), url('../fonts/IBMPlexSerif/ibm-plex-serif-v9-latin-regular.ttf') format('truetype'), url('../fonts/IBMPlexSerif/ibm-plex-serif-v9-latin-regular.svg#IBMPlexSerif') format('svg'); /* Legacy iOS */
}
/* ibm-plex-serif-600 - latin */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/IBMPlexSerif/ibm-plex-serif-v9-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/IBMPlexSerif/ibm-plex-serif-v9-latin-600.eot?#iefix') format('embedded-opentype'), url('../fonts/IBMPlexSerif/ibm-plex-serif-v9-latin-600.woff2') format('woff2'), url('../fonts/IBMPlexSerif/ibm-plex-serif-v9-latin-600.woff') format('woff'), url('../fonts/IBMPlexSerif/ibm-plex-serif-v9-latin-600.ttf') format('truetype'), url('../fonts/IBMPlexSerif/ibm-plex-serif-v9-latin-600.svg#IBMPlexSerif') format('svg'); /* Legacy iOS */
}
/* ibm-plex-serif-700 - latin */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/IBMPlexSerif/ibm-plex-serif-v9-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/IBMPlexSerif/ibm-plex-serif-v9-latin-700.eot?#iefix') format('embedded-opentype'), url('../fonts/IBMPlexSerif/ibm-plex-serif-v9-latin-700.woff2') format('woff2'), url('../fonts/IBMPlexSerif/ibm-plex-serif-v9-latin-700.woff') format('woff'), url('../fonts/IBMPlexSerif/ibm-plex-serif-v9-latin-700.ttf') format('truetype'), url('../fonts/IBMPlexSerif/ibm-plex-serif-v9-latin-700.svg#IBMPlexSerif') format('svg'); /* Legacy iOS */
}


/* merriweather-regular - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Merriweather/merriweather-v22-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/Merriweather/merriweather-v22-latin-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Merriweather/merriweather-v22-latin-regular.woff2') format('woff2'), url('../fonts/Merriweather/merriweather-v22-latin-regular.woff') format('woff'), url('../fonts/Merriweather/merriweather-v22-latin-regular.ttf') format('truetype'), url('../fonts/Merriweather/merriweather-v22-latin-regular.svg#Merriweather') format('svg'); /* Legacy iOS */
}
/* merriweather-700 - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Merriweather/merriweather-v22-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/Merriweather/merriweather-v22-latin-700.eot?#iefix') format('embedded-opentype'), url('../fonts/Merriweather/merriweather-v22-latin-700.woff2') format('woff2'), url('../fonts/Merriweather/merriweather-v22-latin-700.woff') format('woff'), url('../fonts/Merriweather/merriweather-v22-latin-700.ttf') format('truetype'), url('../fonts/Merriweather/merriweather-v22-latin-700.svg#Merriweather') format('svg'); /* Legacy iOS */
}


/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Montserrat/montserrat-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/Montserrat/montserrat-v15-latin-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat/montserrat-v15-latin-regular.woff2') format('woff2'), url('../fonts/Montserrat/montserrat-v15-latin-regular.woff') format('woff'), url('../fonts/Montserrat/montserrat-v15-latin-regular.ttf') format('truetype'), url('../fonts/Montserrat/montserrat-v15-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Montserrat/montserrat-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/Montserrat/montserrat-v15-latin-700.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat/montserrat-v15-latin-700.woff2') format('woff2'), url('../fonts/Montserrat/montserrat-v15-latin-700.woff') format('woff'), url('../fonts/Montserrat/montserrat-v15-latin-700.ttf') format('truetype'), url('../fonts/Montserrat/montserrat-v15-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/Montserrat/montserrat-v15-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/Montserrat/montserrat-v15-latin-600.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat/montserrat-v15-latin-600.woff2') format('woff2'), url('../fonts/Montserrat/montserrat-v15-latin-600.woff') format('woff'), url('../fonts/Montserrat/montserrat-v15-latin-600.ttf') format('truetype'), url('../fonts/Montserrat/montserrat-v15-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-800 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/Montserrat/montserrat-v15-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/Montserrat/montserrat-v15-latin-800.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat/montserrat-v15-latin-800.woff2') format('woff2'), url('../fonts/Montserrat/montserrat-v15-latin-800.woff') format('woff'), url('../fonts/Montserrat/montserrat-v15-latin-800.ttf') format('truetype'), url('../fonts/Montserrat/montserrat-v15-latin-800.svg#Montserrat') format('svg'); /* Legacy iOS */
}


/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Roboto/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/Roboto/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto/roboto-v20-latin-regular.woff2') format('woff2'), url('../fonts/Roboto/roboto-v20-latin-regular.woff') format('woff'), url('../fonts/Roboto/roboto-v20-latin-regular.ttf') format('truetype'), url('../fonts/Roboto/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Roboto/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/Roboto/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto/roboto-v20-latin-700.woff2') format('woff2'), url('../fonts/Roboto/roboto-v20-latin-700.woff') format('woff'), url('../fonts/Roboto/roboto-v20-latin-700.ttf') format('truetype'), url('../fonts/Roboto/roboto-v20-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}


/* barlow-regular - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Barlow/barlow-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/Barlow/barlow-v5-latin-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Barlow/barlow-v5-latin-regular.woff2') format('woff2'), url('../fonts/Barlow/barlow-v5-latin-regular.woff') format('woff'), url('../fonts/Barlow/barlow-v5-latin-regular.ttf') format('truetype'), url('../fonts/Barlow/barlow-v5-latin-regular.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-600 - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/Barlow/barlow-v5-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/Barlow/barlow-v5-latin-600.eot?#iefix') format('embedded-opentype'), url('../fonts/Barlow/barlow-v5-latin-600.woff2') format('woff2'), url('../fonts/Barlow/barlow-v5-latin-600.woff') format('woff'), url('../fonts/Barlow/barlow-v5-latin-600.ttf') format('truetype'), url('../fonts/Barlow/barlow-v5-latin-600.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-700 - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Barlow/barlow-v5-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/Barlow/barlow-v5-latin-700.eot?#iefix') format('embedded-opentype'), url('../fonts/Barlow/barlow-v5-latin-700.woff2') format('woff2'), url('../fonts/Barlow/barlow-v5-latin-700.woff') format('woff'), url('../fonts/Barlow/barlow-v5-latin-700.ttf') format('truetype'), url('../fonts/Barlow/barlow-v5-latin-700.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-800 - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/Barlow/barlow-v5-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/Barlow/barlow-v5-latin-800.eot?#iefix') format('embedded-opentype'), url('../fonts/Barlow/barlow-v5-latin-800.woff2') format('woff2'), url('../fonts/Barlow/barlow-v5-latin-800.woff') format('woff'), url('../fonts/Barlow/barlow-v5-latin-800.ttf') format('truetype'), url('../fonts/Barlow/barlow-v5-latin-800.svg#Barlow') format('svg'); /* Legacy iOS */
}


/* cabin-regular - latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Cabin/cabin-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/Cabin/cabin-v18-latin-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Cabin/cabin-v18-latin-regular.woff2') format('woff2'), url('../fonts/Cabin/cabin-v18-latin-regular.woff') format('woff'), url('../fonts/Cabin/cabin-v18-latin-regular.ttf') format('truetype'), url('../fonts/Cabin/cabin-v18-latin-regular.svg#cabin') format('svg'); /* Legacy iOS */
}
/* cabin-600 - latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/Cabin/cabin-v18-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/Cabin/cabin-v18-latin-600.eot?#iefix') format('embedded-opentype'), url('../fonts/Cabin/cabin-v18-latin-600.woff2') format('woff2'), url('../fonts/Cabin/cabin-v18-latin-600.woff') format('woff'), url('../fonts/Cabin/cabin-v18-latin-600.ttf') format('truetype'), url('../fonts/Cabin/cabin-v18-latin-600.svg#cabin') format('svg'); /* Legacy iOS */
}
/* cabin-700 - latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Cabin/cabin-v18-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/Cabin/cabin-v18-latin-700.eot?#iefix') format('embedded-opentype'), url('../fonts/Cabin/cabin-v18-latin-700.woff2') format('woff2'), url('../fonts/Cabin/cabin-v18-latin-700.woff') format('woff'), url('../fonts/Cabin/cabin-v18-latin-700.ttf') format('truetype'), url('../fonts/Cabin/cabin-v18-latin-700.svg#cabin') format('svg'); /* Legacy iOS */
}