// Root CSS variables

:root {
  // Font:
  --font-family: 'Nunito', sans-serif;
  // Document font size:
  --html-font-size: 7px;
  // Component size:
  --size-components: 1;
  // Spacing:
  --size-spacing: 1;
  // Roundness:
  --roundness: 8px;
  --layout-roundness: 8px;

  // Colors:
  --color-primary: #0c2174;
  --color-select: #e7e9f1;
  --color-text: #212121;
  --color-text-muted: #666666;
  --color-text-disabled: #9e9e9e;
  --color-1: #ffffff;
  --color-2: #f7f7f7;
  --color-3: #ebebeb;
  --color-4: #e0e0e0;
  --color-success: #308140;
  --color-warning: #cc860a;
  --color-danger: #cf3420;
  --color-select-success: #d6e6d9;
  --color-select-warning: #f5e7ce;
  --color-select-danger: #f5d6d2;

  // Header colors:
  --header-color: #ffffff;
  --header-text-color: #212121;

  // Shadows:
  --shadow-sm: 0px 0px 20px rgba(0, 0, 0, 0.1);
  --shadow-md: 0px 0px 60px rgba(0, 0, 0, 0.2);
  --shadow-lg: 0px 0px 50px rgba(0, 0, 0, 0.3);
  --shadow-glow-primary: 0px 0px 20px rgba(12, 33, 116, 0.6);
  --shadow-glow-success: 0px 0px 20px rgba(48, 129, 64, 0.3);
  --shadow-glow-error: 0px 0px 20px rgba(207, 52, 32, 0.3);

  // Images:
  --background-image: '';
  --header-logo: '';

  // Header logo styles, default to size S:
  --header-logo-height: 4.75rem;
  --header-logo-margin: 0;

  // LoanRequestBox Texts:
  --loan-req-commercial-title: "New Commercial Loan Request";
  --loan-req-commercial-description: "You can start a new commercial request by clicking the link below or selecting ’New Commercial Request’ from the menu.";
  --loan-req-commercial-cta: "NEW COMMERCIAL REQUEST";
  --loan-req-consumer-title: "New Consumer Loan Request";
  --loan-req-consumer-description: "You can start a new consumer request by clicking the link below or selecting ’New Consumer Request’ from the menu.";
  --loan-req-consumer-cta: "NEW CONSUMER REQUEST";
}